import React, { ReactNode, useEffect, useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import ControlOne from "../form-sections/facilities/control-one";
import ControlTwo from "./facilities/control-two";
import ControlThree from "./facilities/control-three";
import ControlFour from "./facilities/control-four";
import ControlFive from "./facilities/control-five";
import ControlSix from "./facilities/control-six";
import FormControllerService, {
  Facility,
  groupFacilities,
} from "../services/form-controller.service";
export interface formSectionFiveProps {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
  validFacilitiesCallback: (valid: boolean) => void;
}

const defaultFacility = (): SelectedFacility => ({
  name: "",
  selectedOption: "",
  relatedDates: { to: new Date(), from: new Date() },
  relatedDateAndTimes: { date: new Date(), to: new Date(), from: new Date() },
  totalCost: 0,
  category: "",
  selected: false,
});

export interface SelectedFacility {
  name: string;
  selectedOption: any;
  relatedDates: { to: Date; from: Date };
  relatedDateAndTimes: { date: Date; to: Date; from: Date };
  totalCost?: number;
  category: string;
  selected: boolean;
  valid?: boolean;
}

const FormSectionFive = (props: formSectionFiveProps) => {
  const [_myFacilityList, setMyFacilityList] = useState<SelectedFacility[]>([]);
  const [_currentFormData, setCurrentFormData] = useState<any>();
  const [_facilities, setFacilities] = useState<any>({});
  const [_facilSubTotal, setFacilSubTotal] = useState<number>(0);
  const [_currentFacilityCosts, setFacilityCosts] = useState<any[]>([]);
  const [_activeIndex, setActiveIndex] = useState<number | number[]>(0); // State for accordion active index
  const [isDisabled, setIsDisabled] = useState(false);

  const manageFacility = (newFacility: SelectedFacility) => {
    // console.log("Manage Facility");
    // console.log(newFacility);
    const index = _myFacilityList.findIndex(
      (facility) => facility.name === newFacility.name
    );

    if (index !== -1) {
      _myFacilityList[index] = newFacility;
    } else {
      _myFacilityList.push(newFacility);
    }
    // props.formControllerService._selectedFacilities = _myFacilityList;
    (props.formControllerService._session as any).selectedFacilities = JSON.stringify(_myFacilityList)

    const _selectedAndValid = _myFacilityList.filter((eachFacility) => {
      return eachFacility.selected && eachFacility.valid;
    });

    const _selectedAndInvalid = _myFacilityList.filter((eachFacility) => {
      return eachFacility.selected && !eachFacility.valid;
    });

    if (_selectedAndInvalid.length > 0) {
      props.validFacilitiesCallback(false);
    } else {
      props.validFacilitiesCallback(true);
    }
    // console.log("Selected and valid facilities");
    // console.log(_selectedAndValid);

    const _onlyCosts = _selectedAndValid.filter((eachFacility) => {
      return eachFacility.totalCost !== undefined;
    });

    const _totalCost = _onlyCosts.reduce((acc, eachFacility: any) => {
      return acc + (eachFacility.totalCost as number);
    }, 0);

    // console.log("Total Cost Calulated for subtotal");
    // console.log(_totalCost);

    props.formControllerService._facilitiesCostTotal.totalCost = _totalCost;
    setFacilSubTotal(_totalCost);
  };

  const _gatherFacilities = () => {
    let l = props.formControllerService.getFacilitiesByLocation();
    // console.log(l);
    setFacilities(l);
  };

  const _globalFieldChangeHandler = (e: any) => {
    // get value and id of changing element
    const _id = e.target.id || e.id;
    let _value = e.target.value || e.value;

    if (e.hasOwnProperty("checked")) {
      _value = e.checked;
    }
    props.formControllerService.validateField(_id, _value);
    props.formControllerService.setFormData(_id as any, _value);
  };

  const RenderControl = (facilities: Facility[]): ReactNode[] => {
    let _grouped = groupFacilities(facilities);
    let output: ReactNode[] = [];

    _grouped.forEach((eachOne: Facility | Facility[]) => {
      if (Array.isArray(eachOne)) {
        const _opts = eachOne.map((eachOpts) => {
          return { value: eachOpts.option, label: eachOpts.option };
        });

        output.push(
          <ControlOne
            _facility={eachOne}
            fieldName={(eachOne[0] as Facility).facility}
            toDateFieldName={`${(eachOne[0] as Facility).facility}_TO`}
            fromDateFieldName={`${(eachOne[0] as Facility).facility}_FROM`}
            dropdownOptions={_opts}
            dropdownLabel={`${(eachOne[0] as Facility).facility}_OPTION`}
            _globalFieldChangeHandler={_globalFieldChangeHandler}
            _currentFormData={props.formControllerService._formData}
            _costCallback={manageFacility}
            _disabled={isDisabled}
          />
        );
      } else {
        switch ((eachOne as Facility).control) {
          case "1-lijst met datum van tot":
            console.log("should not be in here...");

            break;
          case "2-datum van tot":
            output.push(
              <ControlTwo
                _facility={eachOne}
                _currentFormData={props.formControllerService._formData}
                toDateFieldName={`${(eachOne as Facility).facility}_TO`}
                fromDateFieldName={`${(eachOne as Facility).facility}_FROM`}
                fieldName={(eachOne as Facility).facility}
                label={(eachOne as Facility).facility}
                _globalFieldChangeHandler={_globalFieldChangeHandler}
                _costCallback={manageFacility}
                _disabled={isDisabled}
              />
            );
            break;
          case "3-datum tijd":
            output.push(
              <ControlThree
                _facility={eachOne}
                description={(eachOne as Facility).facilities_description}
                label={(eachOne as Facility).facility}
                fieldName={(eachOne as Facility).facility}
                toTimeField={`${(eachOne as Facility).facility}_TO`}
                fromTimeField={`${(eachOne as Facility).facility}_FROM`}
                dateFieldName={`${(eachOne as Facility).facility}_DATE`}
                _globalFieldChangeHandler={_globalFieldChangeHandler}
                _currentFormData={props.formControllerService._formData}
                _formControllerService={props.formControllerService}
                _costCallback={manageFacility}
                _disabled={isDisabled}
              />
            );

            break;
          case "4-checkmark":
            output.push(
              <ControlFour
                _facility={eachOne}
                _currentFormData={props.formControllerService._formData}
                fieldName={(eachOne as Facility).facility}
                label={(eachOne as Facility).facility}
                _globalFieldChangeHandler={_globalFieldChangeHandler}
                _costCallback={manageFacility}
                _disabled={isDisabled}
              />
            );

            break;
          case "5-list":
            output.push(
              <ControlFive
                _facility={eachOne}
                _currentFormData={props.formControllerService._formData}
                fieldName={(eachOne as Facility).facility}
                label={(eachOne as Facility).facility}
                options={(eachOne as Facility).option.split("|").map((eO) => {
                  return { value: eO, label: eO };
                })}
                _globalFieldChangeHandler={_globalFieldChangeHandler}
                _costCallback={manageFacility}
                _disabled={isDisabled}
              />
            );

            break;
          case "6-checkmark met tekst":
            output.push(
              <ControlSix
                _facility={eachOne}
                _currentFormData={props.formControllerService._formData}
                fieldName={(eachOne as Facility).facility}
                textareaFieldName={`${(eachOne as Facility).facility}_COMMENT`}
                label={(eachOne as Facility).facility}
                _globalFieldChangeHandler={_globalFieldChangeHandler}
                _costCallback={manageFacility}
                _disabled={isDisabled}
              />
            );
            break;

          default:
            return <></>;
        }
      }
    });

    return output as ReactNode[];
  };

  // a function to format a number (either a int or float) to a euro currency
  const formatToEuro = (num: number) => {
    return `€${num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")}`;
  };

  useEffect(() => {
    _gatherFacilities();

    if (
      (props.formControllerService._READ_ONLY &&
        props.formControllerService._CURRENT_ROLE !== "ADMIN") ||
      props.formControllerService.getSession()?.status == "COMPLETED_APPROVED"
    ) {
      setIsDisabled(true);
    }
    const _selected = props.formControllerService.getSelectedFacilities();
    // filter to only selected == true and valid == true
    const _selectedAndValid = _selected.filter((eachFacility) => {
      return eachFacility.selected && eachFacility.valid;
    });
    const _selectedAndInvalid = _selected.filter((eachFacility) => {
      return eachFacility.selected && !eachFacility.valid;
    });

    if (_selectedAndInvalid.length > 0) {
      props.validFacilitiesCallback(false);
    } else {
      props.validFacilitiesCallback(true);
    }
    console.log("Selected and valid facilities");
    console.log(_selectedAndValid);
    setMyFacilityList(_selectedAndValid);
    const _onlyCosts = _selectedAndValid.filter((eachFacility) => {
      return eachFacility.totalCost !== undefined;
    });

    const _totalCost = _onlyCosts.reduce((acc, eachFacility: any) => {
      return acc + (eachFacility.totalCost as number);
    }, 0);

    console.log("Total Cost Calulated for subtotal");
    console.log(_totalCost);

    props.formControllerService._facilitiesCostTotal.totalCost = _totalCost;
    setFacilSubTotal(_totalCost);
  }, []);

  // in a useEffect lets activate each of the accordion tabs and then set the active index to the first one
  useEffect(() => {
    const arr: number[] = [];
    if (Object.keys(_facilities).length > 0) {
      for (let i = 0; i < Object.keys(_facilities).length; i++) {
        arr.push(i);
      }
      setActiveIndex(arr);
    }
  }, [_facilities]);

  return (
    <div style={{ width: "100%", padding: 10, height: "100%" }}>
      <h3>
        Facliteiten: {props.formControllerService._formData.locatie}: Subtotaal:{" "}
        {formatToEuro(Number(_facilSubTotal))}
      </h3>

      <Accordion
        multiple
        activeIndex={_activeIndex as number[]}
        onTabChange={(e) => setActiveIndex(e.index as number)}
      >
        {Object.keys(_facilities).length > 0
          ? Object.keys(_facilities).map((eachOne: string, index: number) => {
              return (
                <AccordionTab key={index} header={eachOne}>
                  <div style={{ maxHeight: 500, overflowY: "scroll" }}>
                    {RenderControl(_facilities[eachOne]).map(
                      (eachComp: ReactNode) => {
                        return eachComp;
                      }
                    )}
                  </div>
                </AccordionTab>
              );
            })
          : null}
      </Accordion>
    </div>
  );
};

export default FormSectionFive;
