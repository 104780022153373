import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import FormControllerService from "../services/form-controller.service";
import { Dialog } from "primereact/dialog";
import { Checkbox } from "primereact/checkbox";
import { InputMask } from "primereact/inputmask";

export interface formSectionThreeProps {
  formControllerService: FormControllerService; // this service will manage all values from the inputs
}
const FormSectionThree = (props: formSectionThreeProps) => {
  const [formHeader, setFormHeader] = useState<string>();
  const [_showButton, setShowButton] = useState<boolean>(false);
  const [_currentFormData, setCurrentFormData] = useState<any>();
  const [focusedFields, setFocusedFields] = useState(new Set());
  const dropdownOptions = [
    { label: "Ja", value: "ja" },
    { label: "Nee", value: "nee" },
  ];
  const [isDisabled, setIsDisabled] = useState(false);

  const handleFocus = (field: any) => {
    setFocusedFields((prev) => new Set(prev).add(field));
  };

  useEffect(() => {
    setCurrentFormData(props.formControllerService._formData);
    const r = props.formControllerService._formData.rightsType;
    if (r === "r") {
      setFormHeader("Informatie Rechthebbende");
    } else if (r == "b") {
      setFormHeader("Informatie Belanghebbende");
    }
    _unpackPreviousSession();
    if((props.formControllerService._READ_ONLY && props.formControllerService._CURRENT_ROLE !== 'ADMIN') || props.formControllerService.getSession()?.status == 'COMPLETED_APPROVED'){
      setIsDisabled(true)
    }
  }, []);

  const _globalFieldChangeHandler = (e: any) => {
    // console.log(e);
    // get value and id of changing element
    const _id = e.target.id || e.id;
    let _value = e.target.value || e.value;
    if (e.hasOwnProperty("checked")) {
      _value = e.checked;
    }
    if (_id == "rechthebbende_gewijzigd" && _value == "ja") {
      setShowButton(true);
    } else if (_id == "rechthebbende_gewijzigd" && _value == "nee") {
      setShowButton(false);
    }
    props.formControllerService.validateField(_id, _value);
    props.formControllerService.setFormData(_id as any, _value);
    setCurrentFormData({
      ..._currentFormData,
      [_id]: _value,
    });
  };

  const showRegisterForm = () => {
    const link = document.createElement('a');
    
    // Set the href to the file path or URL
    link.href = '/form.docx'; // Update this to the correct file path or URL
    
    // Set the download attribute with the file name
    link.download = 'Formulier Wijzig Rechthebbende.docx';
    
    // Programmatically click the anchor to trigger the download
    document.body.appendChild(link); // Append to body to make it clickable
    link.click();
    
    // Remove the anchor from the document
    document.body.removeChild(link);
  };

  const _unpackPreviousSession = () => {
    if (
      props.formControllerService._hasPrevSession
    ) {
      
      if (props.formControllerService._formData.rechthebbende_gewijzigd) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
      setCurrentFormData({
        ...props.formControllerService._formData      });
    } else {
      setCurrentFormData({
        ...props.formControllerService._formData      });
    }
  };

  const showButton = () => {
    setShowButton(true);
  };

  return (
    <div style={{ width: "100%", padding: 15, height: "100%", paddingTop: 0 }}>
      <div className="p-fluid">
        <h3>{formHeader}</h3>

        <div className="p-grid">
          <div className="p-col-12 p-md-6">
            <div
              style={{
                display: `${
                  props.formControllerService._formData.rightsType == "r" && props.formControllerService._formData.nieuw_of_bestaand_graf == "Bestaand"
                    ? "flex"
                    : "none"
                }`,
              }}
              className="padding"
            >
              <div style={{ width: "50%" }}>
                <label htmlFor="rechthebbende_gewijzigd">
                  Rechthebbende gewijzigd?:
                </label>
              </div>
              <div style={{ width: "40%", display: "flex" }}>
                <Dropdown
                   disabled={isDisabled}  id="rechthebbende_gewijzigd"
                  options={dropdownOptions}
                  style={{ width: "50%" }}
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.rechthebbende_gewijzigd}
                  onFocus={() => handleFocus("rechthebbende_gewijzigd")}
                />
                {_showButton ? (
                  <Button
                  disabled={isDisabled}
                    label="Formulier Wijzig Rechthebbende"
                    onClick={showRegisterForm}
                    style={{ backgroundColor: "#44772C", width: "50%" }}
                  />
                ) : null}
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("rechthebbende_gewijzigd") ||
                  _currentFormData?.rechthebbende_gewijzigd) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "rechthebbende_gewijzigd",
                        _currentFormData?.rechthebbende_gewijzigd
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="bsn_nummer_rechthebbende">BSN nummer:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="bsn_nummer_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.bsn_nummer_rechthebbende}
                  onFocus={() => handleFocus("bsn_nummer_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("bsn_nummer_rechthebbende") ||
                  _currentFormData?.bsn_nummer_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "bsn_nummer_rechthebbende",
                        _currentFormData?.bsn_nummer_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="achternaam_rechthebbende">Achternaam:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="achternaam_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.achternaam_rechthebbende}
                  onFocus={() => handleFocus("achternaam_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("achternaam_rechthebbende") ||
                  _currentFormData?.achternaam_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "achternaam_rechthebbende",
                        _currentFormData?.achternaam_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="voornamen_letters_rechthebbende">
                  Voornamen / letters:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="voornamen_letters_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.voornamen_letters_rechthebbende}
                  onFocus={() => handleFocus("voornamen_letters_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("voornamen_letters_rechthebbende") ||
                  _currentFormData?.voornamen_letters_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "voornamen_letters_rechthebbende",
                        _currentFormData?.voornamen_letters_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="geslacht_rechthebbende">Geslacht:</label>
              </div>
              <div style={{ width: "40%" }}>
                <Dropdown
                   disabled={isDisabled}  id="geslacht_rechthebbende"
                  options={[
                    { label: "Man", value: "man" },
                    { label: "Vrouw", value: "vrouw" },
                  ]}
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.geslacht_rechthebbende}
                  onFocus={() => handleFocus("geslacht_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("geslacht_rechthebbende") ||
                  _currentFormData?.geslacht_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "geslacht_rechthebbende",
                        _currentFormData?.geslacht_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="geboortedatum_rechthebbende">
                  Geboortedatum:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputMask
                  mask="99-99-9999"
                   disabled={isDisabled}  id="geboortedatum_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.geboortedatum_rechthebbende}
                  onFocus={() => handleFocus("geboortedatum_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("geboortedatum_rechthebbende") ||
                  _currentFormData?.geboortedatum_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "geboortedatum_rechthebbende",
                        _currentFormData?.geboortedatum_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="geboorteplaats_rechthebbende">
                  Geboorteplaats:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="geboorteplaats_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.geboorteplaats_rechthebbende}
                  onFocus={() => handleFocus("geboorteplaats_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("geboorteplaats_rechthebbende") ||
                  _currentFormData?.geboorteplaats_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "geboorteplaats_rechthebbende",
                        _currentFormData?.geboorteplaats_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="straat_en_huisnummer_rechthebbende">
                  Straat en huisnummer:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="straat_en_huisnummer_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.straat_en_huisnummer_rechthebbende}
                  onFocus={() =>
                    handleFocus("straat_en_huisnummer_rechthebbende")
                  }
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("straat_en_huisnummer_rechthebbende") ||
                  _currentFormData?.straat_en_huisnummer_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "straat_en_huisnummer_rechthebbende",
                        _currentFormData?.straat_en_huisnummer_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="adres_toevoegingen_rechthebbende">
                  Eventuele toevoegingen:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="adres_toevoegingen_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.adres_toevoegingen_rechthebbende}
                  onFocus={() =>
                    handleFocus("adres_toevoegingen_rechthebbende")
                  }
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
               
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="postcode_rechthebbende">Postcode:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="postcode_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.postcode_rechthebbende}
                  onFocus={() => handleFocus("postcode_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("postcode_rechthebbende") ||
                  _currentFormData?.postcode_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "postcode_rechthebbende",
                        _currentFormData?.postcode_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="woonplaats_rechthebbende">Woonplaats:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="woonplaats_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.woonplaats_rechthebbende}
                  onFocus={() => handleFocus("woonplaats_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("woonplaats_rechthebbende") ||
                  _currentFormData?.woonplaats_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "woonplaats_rechthebbende",
                        _currentFormData?.woonplaats_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="gemeente_rechthebbende">Gemeente:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="gemeente_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.gemeente_rechthebbende}
                  onFocus={() => handleFocus("gemeente_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("gemeente_rechthebbende") ||
                  _currentFormData?.gemeente_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "gemeente_rechthebbende",
                        _currentFormData?.gemeente_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="land_rechthebbende">Land:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="land_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.land_rechthebbende}
                  onFocus={() => handleFocus("land_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("land_rechthebbende") ||
                  _currentFormData?.land_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "land_rechthebbende",
                        _currentFormData?.land_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="telefoonnummer_rechthebbende">
                  Telefoonnummer:
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="telefoonnummer_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.telefoonnummer_rechthebbende}
                  onFocus={() => handleFocus("telefoonnummer_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("telefoonnummer_rechthebbende") ||
                  _currentFormData?.telefoonnummer_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "telefoonnummer_rechthebbende",
                        _currentFormData?.telefoonnummer_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label htmlFor="email_rechthebbende">E-mailadres:</label>
              </div>
              <div style={{ width: "40%" }}>
                <InputText
                   disabled={isDisabled}  id="email_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  value={_currentFormData?.email_rechthebbende}
                  onFocus={() => handleFocus("email_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              >
                {(focusedFields.has("email_rechthebbende") ||
                  _currentFormData?.email_rechthebbende) && (
                  <i
                    className={`pi ${
                      props.formControllerService.validateField(
                        "email_rechthebbende",
                        _currentFormData?.email_rechthebbende
                      )
                        ? "pi-check green"
                        : "pi-times red"
                    }`}
                  />
                )}
              </div>
            </div>
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label
                  style={{ marginRight: 10 }}
                  htmlFor="brieven_digitaal_rechthebbende"
                >
                  Brieven van de gemeente digitaal ontvangen?
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <Checkbox
                   disabled={isDisabled}  id="brieven_digitaal_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  checked={_currentFormData?.brieven_digitaal_rechthebbende}
                  onFocus={() => handleFocus("brieven_digitaal_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              ></div>
            </div>
            <div style={{ display: "flex" }} className="padding">
              <div style={{ width: "50%" }}>
                <label
                  style={{ marginRight: 10 }}
                  htmlFor="toestemming_rechthebbende"
                >
                  Toestemming {props.formControllerService._formData.rightsType == 'b' ?  'belanghebbende' : 'rechthebbende'}
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <Checkbox
                   disabled={isDisabled}  id="toestemming_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  checked={_currentFormData?.toestemming_rechthebbende}
                  onFocus={() => handleFocus("toestemming_rechthebbende")}
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              ></div>
            </div>
            <div style={{ display: `${props.formControllerService._formData.rightsType == 'b' ? 'none' : 'flex'}` }} className="padding">
              <div style={{ width: "50%" }}>
                <label
                  style={{ marginRight: 10 }}
                  htmlFor="inschrijven_overboeken_rechthebbende"
                >
                  Inschrijven/overboeken rechthebbende
                </label>
              </div>
              <div style={{ width: "40%" }}>
                <Checkbox
                   disabled={isDisabled}  id="inschrijven_overboeken_rechthebbende"
                  onChange={_globalFieldChangeHandler}
                  checked={
                    _currentFormData?.inschrijven_overboeken_rechthebbende
                  }
                  onFocus={() =>
                    handleFocus("inschrijven_overboeken_rechthebbende")
                  }
                />
              </div>
              <div
                style={{
                  width: "10%",
                  margin: "auto",
                  marginLeft: 10,
                  marginTop: 5,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSectionThree;
