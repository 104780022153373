import { debug } from "console";
import { SelectedFacility } from "../form-sections/section-five";
import { SessionObject } from "./client-data-service";
export interface FormData {
    rightsType?: string; // depending on the funeral type
    uitvaart_nummer: string | undefined; // Funeral Number
    ondernemer_bedrijfsnaam: string; // Entrepreneur Business Name
    kvk_nummer: string | undefined; // Chamber of Commerce Number
    ondernemer_email: string; // Entrepreneur Email
    uitvaartleider_naam: string; // Funeral Director Name
    uitvaartleider_telefoon: string; // Funeral Director Phone
    ondernemer_registratienummer: string; // Entrepreneur Registration Number
    achternaam_overledene: string; // Surname of Deceased
    locatie: string; // Location
    datum_uitvaart: Date | string | undefined; // Funeral Date
    aanvangstijd_uitvaart: Date | string | undefined; // Funeral Start Time
    aanvangstijd_groeve: Date | string | undefined; // Grave Start Time
    soort_begrafenis: string; // Type of Funeral
    nieuw_of_bestaand_graf: string; // new or existing grave
    bestaand_graf_verlenging: string; // extend existing
    bestaand_graf_verlenging_aantal_jaren: string; // years extend existing
    type_rechten: string; // Type of Rights
    rechthebbende_gewijzigd: boolean; // Rights Holder Changed
    bsn_nummer_rechthebbende: string; // Rights Holder BSN Number
    achternaam_rechthebbende: string; // Rights Holder Surname
    voornamen_letters_rechthebbende: string; // Rights Holder First Names/Initials
    geslacht_rechthebbende: string; // Rights Holder Gender
    geboortedatum_rechthebbende: Date | string; // Rights Holder Birthdate
    geboorteplaats_rechthebbende: string; // Rights Holder Birthplace
    geboorteland_rechthebbende: string; // Rights Holder Birth Country
    straat_en_huisnummer_rechthebbende: string; // Rights Holder Street and House Number
    adres_toevoegingen_rechthebbende: string; // Rights Holder Address Additions
    postcode_rechthebbende: string; // Rights Holder Postal Code
    woonplaats_rechthebbende: string; // Rights Holder Place of Residence
    gemeente_rechthebbende: string; // Rights Holder Municipality
    land_rechthebbende: string; // Rights Holder Country
    telefoonnummer_rechthebbende: string; // Rights Holder Phone Number
    email_rechthebbende: string; // Rights Holder Email
    brieven_digitaal_rechthebbende: boolean; // Digital Letters to Rights Holder
    toestemming_rechthebbende: boolean; // Rights Holder Permission
    inschrijven_overboeken_rechthebbende: boolean; // Register/Transfer Rights Holder
    bsn_nummer_overledene: string; // Deceased BSN Number
    voornamen_letters_overledene: string; // Deceased First Names/Initials
    geslacht_overledene: string; // Deceased Gender
    geboortedatum_overledene: Date | string; // Deceased Birthdate
    geboorteplaats_overledene: string; // Deceased Birthplace
    geboorteland_overledene: string; // Deceased Birth Country
    naam_partner_overledene: string; // Deceased Partner's Name
    laatste_woonplaats_overledene: string; // Deceased Last Place of Residence
    datum_overlijden: Date | string; // Date of Death
    gemeente_overlijden: string; // Municipality of Death
    land_overlijden: string; // Country of Death
    categorie: string; // Category
    faciliteit: string; // Facility
    faciliteit_ja_nee: boolean; // Facility Yes/No
    optie: string; // Option
    toelichting: string; // Explanation
    bijzonderheden: string; // Particulars
    voor_en_achternaam_ondertekening_uitvaartonderneming: string; // Full Name for Signing by Funeral Company
    datum_ondertekening_uitvaartonderneming: Date | string; // Signing Date by Funeral Company
    digitale_handtekening_uitvaartonderneming: string; // Digital Signature by Funeral Company
    grafnummer_met_laag: string; // Grave Number with Layer
    toelichting_beheerder: string; // Manager's Explanation
    voor_en_achternaam_beheerder: string; // Full Name of Manager
    datum_ondertekening_beheerder: string; // Manager's Signing Date
    digitale_handtekening_beheerder: string; // Manager's Digital Signature
    nieuw_graf_aantal_jaren: number // years
    totalCost?: number



}
export interface Cost {
    _id: string;
    burial_type?: string;
    new_exist?: string;
    years?: number;
    cost?: string;
    type?: string;
    cost_description?: string;
}

export interface Facility {
    _id: string;
    location: string;
    seq: number;
    category: string;
    facility: string;
    control: string;
    facilities_description: string;
    option: string;
    price: number;
    price_unit: string;
    surcharge: number;
    max: number;
    extra: number;
}

export interface FuneralType {
    burial_type: string;
    location_DeLier: boolean;
    location_Maasdijk: boolean;
    location_Monster: boolean;
    location_Naaldwijk: boolean;
    location_Wateringen: boolean;
    location_sGravenzande: boolean;
    rights_type: string;
    _id: string;
    order:number;
}

export interface Location {
    _id: string;
    location: string;
    email_administrator: string;
}

// default date
const d = new Date();
d.setHours(0, 0, 0, 0);

const c = new Date();
c.setFullYear(2024)
c.setMonth(0)
c.setDate(1)
c.setHours(0, 0, 0, 0);

export const defaultFormData: FormData = {
    rightsType: '',
    uitvaart_nummer: '', // Funeral Number
    ondernemer_bedrijfsnaam: '', // Entrepreneur Business Name
    kvk_nummer: '', // Chamber of Commerce Number
    ondernemer_email: '', // Entrepreneur Email
    uitvaartleider_naam: '', // Funeral Director Name
    uitvaartleider_telefoon: '', // Funeral Director Phone
    ondernemer_registratienummer: '', // Entrepreneur Registration Number
    achternaam_overledene: '', // Surname of Deceased
    locatie: '', // Location
    datum_uitvaart: '', // Funeral Date
    aanvangstijd_uitvaart: '', // Funeral Start Time
    aanvangstijd_groeve: '', // Grave Start Time
    soort_begrafenis: '', // Type of Funeral
    type_rechten: '', // Type of Rights
    rechthebbende_gewijzigd: false, // Rights Holder Changed
    bsn_nummer_rechthebbende: '', // Rights Holder BSN Number
    achternaam_rechthebbende: '', // Rights Holder Surname
    voornamen_letters_rechthebbende: '', // Rights Holder First Names/Initials
    geslacht_rechthebbende: '', // Rights Holder Gender
    geboortedatum_rechthebbende: '', // Rights Holder Birthdate
    geboorteplaats_rechthebbende: '', // Rights Holder Birthplace
    geboorteland_rechthebbende: '', // Rights Holder Birth Country
    straat_en_huisnummer_rechthebbende: '', // Rights Holder Street and House Number
    adres_toevoegingen_rechthebbende: '', // Rights Holder Address Additions
    postcode_rechthebbende: '', // Rights Holder Postal Code
    woonplaats_rechthebbende: '', // Rights Holder Place of Residence
    gemeente_rechthebbende: '', // Rights Holder Municipality
    land_rechthebbende: '', // Rights Holder Country
    telefoonnummer_rechthebbende: '', // Rights Holder Phone Number
    email_rechthebbende: '', // Rights Holder Email
    brieven_digitaal_rechthebbende: false, // Digital Letters to Rights Holder
    toestemming_rechthebbende: false, // Rights Holder Permission
    inschrijven_overboeken_rechthebbende: false, // Register/Transfer Rights Holder
    bsn_nummer_overledene: '', // Deceased BSN Number
    voornamen_letters_overledene: '', // Deceased First Names/Initials
    geslacht_overledene: '', // Deceased Gender
    geboortedatum_overledene: '', // Deceased Birthdate
    geboorteplaats_overledene: '', // Deceased Birthplace
    geboorteland_overledene: '', // Deceased Birth Country
    naam_partner_overledene: '', // Deceased Partner's Name
    laatste_woonplaats_overledene: '', // Deceased Last Place of Residence
    datum_overlijden: '', // Date of Death
    gemeente_overlijden: '', // Municipality of Death
    land_overlijden: '', // Country of Death
    categorie: '', // Category
    faciliteit: '', // Facility
    faciliteit_ja_nee: false, // Facility Yes/No
    optie: '', // Option
    toelichting: '', // Explanation
    bijzonderheden: '', // Particulars
    voor_en_achternaam_ondertekening_uitvaartonderneming: '', // Full Name for Signing by Funeral Company
    datum_ondertekening_uitvaartonderneming: '', // Signing Date by Funeral Company
    digitale_handtekening_uitvaartonderneming: '', // Digital Signature by Funeral Company
    grafnummer_met_laag: '', // Grave Number with Layer
    toelichting_beheerder: '', // Manager's Explanation
    voor_en_achternaam_beheerder: '', // Full Name of Manager
    datum_ondertekening_beheerder: '', // Manager's Signing Date
    digitale_handtekening_beheerder: '', // Manager's Digital Signature
    nieuw_of_bestaand_graf: '', // new or existing grave
    bestaand_graf_verlenging: '', // extend existing?
    bestaand_graf_verlenging_aantal_jaren: '', // years to extend
    nieuw_graf_aantal_jaren: 0,
    totalCost: 0
};


function areDatesSame(date1: Date, date2: Date) {
    // Ensure the input dates are valid Date objects
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
        throw new Error("Invalid input: Both arguments should be Date objects.");
    }

    // Compare the year, month, and day
    return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getTime() === date2.getTime()
    );
}

export function groupFacilities(facilities: Facility[]): (Facility | Facility[])[] {
    const grouped: { [key: string]: Facility[] } = {};
    const result: (Facility | Facility[])[] = [];

    // Group facilities by seq, category, and facility
    for (const facility of facilities) {
        const key = `${facility.seq}-${facility.category}-${facility.facility}`;
        if (!grouped[key]) {
            grouped[key] = [];
        }
        grouped[key].push(facility);
    }

    // Create the final result array
    for (const key in grouped) {
        if (grouped[key].length > 1) {
            result.push(grouped[key]);
        } else {
            result.push(grouped[key][0]);
        }
    }

    return result;
}

// a function to convert a string mm-dd-yyyy to a date object
function parseDateFromEUString(input: string) {
    const parts = input.split('-');
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
}
export default class FormControllerService {
    private static instance: FormControllerService;
    public _formData: FormData = defaultFormData;
    public _location: string | undefined = undefined;
    public _funeralType: string | undefined = undefined;
    public _funeralDate: Date | undefined = undefined;
    public _facilities: any[] = [];
    public _costs: Cost[] = [];
    public _costTotal: number = 0;
    public _rightsType: string | undefined = undefined;
    public _controlData: any = {};
    public _emailAdmin: string | undefined = undefined;
    public _holidaySurCharge: boolean = false;
    public _session: SessionObject | undefined;
    public _burialCosts: Cost[] = [];
    public _hasPrevSession: boolean = false;
    public _hasCosts: boolean = false;
    public _newSession: boolean = false;
    public _burialCostTotal: any = '0';
    public _burialCostTotalUnFormatted: number = 0;
    public _facilitiesCostTotal: any = {facility: '',
        category: 'Subtotaal',
        option: '',
        price: '',
        price_unit: '',
        totalCost: 0};
    public _facilitiesTotalcostAmount: number = 0;
    public _facilitiesTotalcostAmountFormatted: string = '';
    public _totalCosts: number = 0;
    public _totalCostsFormatted: string = '';
    public _selectedFacilities: SelectedFacility[] = []
    public _READ_ONLY: boolean = false;
    public _CURRENT_ROLE: 'ADMIN' | 'USER' = 'USER';
    public _COMPLETE: boolean = false;
    constructor() { }


    public static getInstance(): FormControllerService {
        if (!FormControllerService.instance) {
          FormControllerService.instance = new FormControllerService();
        }
        return FormControllerService.instance;
      }
      
    public getNextNumber() {
        return this._controlData.NEXTNUMBERS[0].currentNumber;
    }
    public validateField(key: keyof FormData, value: any): boolean {
        switch (key) {
            case 'rightsType':
                // Example validation: rightsType should be a string
                return typeof value === 'string' || value === undefined;

            case 'uitvaart_nummer':
                // Example validation: uitvaart_nummer should be a string or undefined
                return typeof value === 'string' || value === undefined;

            case 'ondernemer_bedrijfsnaam':
                // Example validation: ondernemer_bedrijfsnaam should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'kvk_nummer':
                // Example validation: kvk_nummer should be a string or undefined
                return typeof value === 'string' && this.checkKvKNumber(value as any);

            case 'ondernemer_email':
                // Example validation: ondernemer_email should be a valid email
                return typeof value === 'string' && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

            case 'uitvaartleider_naam':
                // Example validation: uitvaartleider_naam should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'uitvaartleider_telefoon':
                // Example validation: uitvaartleider_telefoon should be a non-empty string
                return typeof value === 'string' && value.trim() !== '' && this.isValidDutchPhoneNumber(value);

            case 'ondernemer_registratienummer':
                // Example validation: ondernemer_registratienummer should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'achternaam_overledene':
                // Example validation: achternaam_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'locatie':
                // Example validation: locatie should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'datum_uitvaart':



                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // let y = this.checkForHoliday(value)
                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));

            case 'aanvangstijd_uitvaart':

            let check = false;
                // we should have a string like "11:00" or "09:30" - 24 hour format if we do not have a complete hh:mm then return false
                // also if the hh is before 9 or after 15:30 return false
                if (typeof value === 'string') {
                    let parts = value.split(':');
                    if (parts.length == 2) {
                        let hours = parseInt(parts[0]);
                        let minutes = parseInt(parts[1]);
                        if (hours >= 9 && hours <= 15 && minutes >= 0 && minutes <= 30) {
                            check = true;
                        }
                    }
                }

                // Example validation: aanvangstijd_uitvaart should be a valid date string, Date object, or undefined
                return check

            case 'aanvangstijd_groeve':
                let check2 = false;
                // we should have a string like "11:00" or "09:30" - 24 hour format if we do not have a complete hh:mm then return false
                // also if the hh is before 9 or after 15:30 return false
                if (typeof value === 'string') {
                    let parts = value.split(':');
                    if (parts.length == 2) {
                        let hours = parseInt(parts[0]);
                        let minutes = parseInt(parts[1]);
                        if (hours >= 9 && hours <= 15 && minutes >= 0 && minutes <= 30) {
                            check2 = true;
                        }
                    }
                }

                // Example validation: aanvangstijd_uitvaart should be a valid date string, Date object, or undefined
                return check2
            case 'soort_begrafenis':
                // Example validation: soort_begrafenis should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'nieuw_of_bestaand_graf':
                // Example validation: nieuw_of_bestaand_graf should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'bestaand_graf_verlenging':
                // Example validation: bestaand_graf_verlenging should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'bestaand_graf_verlenging_aantal_jaren':
                // Example validation: bestaand_graf_verlenging_aantal_jaren should be a non-empty string
                return typeof value === 'string' || typeof value === 'number';

            case 'type_rechten':
                // Example validation: type_rechten should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'rechthebbende_gewijzigd':
                // console.log(value)
                // Example validation: rechthebbende_gewijzigd should be a boolean
                return value === 'ja' || value === 'nee';

            case 'bsn_nummer_rechthebbende':
                // Example validation: bsn_nummer_rechthebbende should be a non-empty string
                return true

            case 'achternaam_rechthebbende':
                // Example validation: achternaam_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'voornamen_letters_rechthebbende':
                // Example validation: voornamen_letters_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geslacht_rechthebbende':
                // Example validation: geslacht_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geboortedatum_rechthebbende':
                if (value == '') {
                    return false
                }
                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));

            case 'geboorteplaats_rechthebbende':
                // Example validation: geboorteplaats_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geboorteland_rechthebbende':
                // Example validation: geboorteland_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'straat_en_huisnummer_rechthebbende':
                // Example validation: straat_en_huisnummer_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'adres_toevoegingen_rechthebbende':
                // Example validation: adres_toevoegingen_rechthebbende should be a non-empty string
                return true

            case 'postcode_rechthebbende':
                // Example validation: postcode_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'woonplaats_rechthebbende':
                // Example validation: woonplaats_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'gemeente_rechthebbende':
                // Example validation: gemeente_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'land_rechthebbende':
                // Example validation: land_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'telefoonnummer_rechthebbende':
                // Example validation: telefoonnummer_rechthebbende should be a non-empty string
                return typeof value === 'string' && value.trim() !== '' && this.isValidDutchPhoneNumber(value);

            case 'email_rechthebbende':
                // Example validation: email_rechthebbende should be a valid email
                return true

            case 'brieven_digitaal_rechthebbende':
                // console.log(value)
                // Example validation: brieven_digitaal_rechthebbende should be a boolean
                return typeof value === 'boolean';

            case 'toestemming_rechthebbende':
                // Example validation: toestemming_rechthebbende should be a boolean
                return typeof value === 'boolean';

            case 'inschrijven_overboeken_rechthebbende':
                // Example validation: inschrijven_overboeken_rechthebbende should be a boolean
                return typeof value === 'boolean';

            case 'bsn_nummer_overledene':
                // Example validation: bsn_nummer_overledene should be a non-empty string
                return true

            case 'voornamen_letters_overledene':
                // Example validation: voornamen_letters_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geslacht_overledene':
                // Example validation: geslacht_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geboortedatum_overledene':
                if (value == '') {
                    return false
                }
                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));


            case 'geboorteplaats_overledene':
                // Example validation: geboorteplaats_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'geboorteland_overledene':
                // Example validation: geboorteland_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'naam_partner_overledene':
                // Example validation: naam_partner_overledene should be a non-empty string
                return true

            case 'laatste_woonplaats_overledene':
                // Example validation: laatste_woonplaats_overledene should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'datum_overlijden':
                if (value == '') {
                    return false
                }
                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));

               
            case 'gemeente_overlijden':
                // Example validation: gemeente_overlijden should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'land_overlijden':
                // Example validation: land_overlijden should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'categorie':
                // Example validation: categorie should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'faciliteit':
                // Example validation: faciliteit should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'faciliteit_ja_nee':
                // Example validation: faciliteit_ja_nee should be a boolean
                return typeof value === 'boolean';

            case 'optie':
                // Example validation: optie should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'toelichting':
                // Example validation: toelichting should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'bijzonderheden':
                // Example validation: bijzonderheden should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'voor_en_achternaam_ondertekening_uitvaartonderneming':
                // Example validation: voor_en_achternaam_ondertekening_uitvaartonderneming should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'datum_ondertekening_uitvaartonderneming':
                if (value == '') {
                    return false
                }
                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));

            case 'digitale_handtekening_uitvaartonderneming':
                if (value == '') {
                    return false
                }
           

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return (value);


            case 'grafnummer_met_laag':
                // Example validation: grafnummer_met_laag should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'toelichting_beheerder':
                // Example validation: toelichting_beheerder should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'voor_en_achternaam_beheerder':
                // Example validation: voor_en_achternaam_beheerder should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'datum_ondertekening_beheerder':
                if (value == '') {
                    return false
                }
                if (typeof value === 'string') {
                    value = parseDateFromEUString(value);
                }

                // Example validation: datum_uitvaart should be a valid date string, Date object, or undefined
                return value instanceof Date || (typeof value === 'string' && !isNaN(Date.parse(value)));

            case 'digitale_handtekening_beheerder':
                // Example validation: digitale_handtekening_beheerder should be a non-empty string
                return typeof value === 'string' && value.trim() !== '';

            case 'nieuw_graf_aantal_jaren':
                // Example validation: nieuw_graf_aantal_jaren should be a number
                return typeof value === 'number';

            default:
                return false; // Invalid field key
        }
    }

    public getCurrentRole() {
        return this._CURRENT_ROLE
    }

    public setCurrentRole(role: 'ADMIN' | 'USER') {
        this._CURRENT_ROLE = role
    }

    public getSelectedFacilities() {
        console.log(this._selectedFacilities)
        return this._selectedFacilities
    }

    public setSelectedFacilities(data: any) {
        this._selectedFacilities = data;
    }

    public setControlData(cMap: any) {
        this._controlData = cMap;
        this._controlData.HOLIDAYS = this._controlData.HOLIDAYS.map((eachHoliday: any) => {
            return { holiday: eachHoliday.holiday, holiday_date: parseDate(eachHoliday.holiday_date) };
        })
    }

    public getGeneralCosts(type: string) {
        return this._controlData.COSTS.filter((eachCost: Cost) => {
            return eachCost.type == 'Voorlopen' && eachCost.burial_type == type
        })
    }
    public getGeneralCostsPerBurialType() {

        if (this._funeralType) {
            let _gencosts = this._controlData.COSTS.filter((eachCost: Cost) => {
                return (eachCost.burial_type == this._funeralType ) && (eachCost.new_exist === this._formData.nieuw_of_bestaand_graf) && eachCost.years == (this._formData.nieuw_of_bestaand_graf == 'Nieuw' ? this._formData.nieuw_graf_aantal_jaren : this._formData.bestaand_graf_verlenging_aantal_jaren)
            })
            const _burialCost = this._controlData.COSTS.filter((eCost: Cost) => {
                return eCost.burial_type  == this._funeralType  && eCost.type === 'Begraven'
            })

            if (this._formData.bestaand_graf_verlenging == 'Nee' && this._formData.nieuw_of_bestaand_graf == 'Bestaand') {
                _gencosts = []
            }

            return [..._gencosts, ..._burialCost];
        }
    }

    public isValidDutchPhoneNumber(phoneNumber: string): boolean {

        // Remove any spaces, dashes, or parentheses
        const sanitizedNumber = phoneNumber.replace(/[\s\-()]/g, '');
        if (sanitizedNumber.length < 9) {
            return false
        }
        // Regular expression to match Dutch phone numbers
        const dutchPhoneRegex = /^(?:\+31|0)\d{9}$/;

        return dutchPhoneRegex.test(sanitizedNumber);
    }

    public getFormData = (key: keyof FormData) => {
        return this._formData[key];
    }


    public pageOneValid() {
        const fields = [
            "ondernemer_bedrijfsnaam",
            "kvk_nummer",
            "ondernemer_email",
            "uitvaartleider_naam",
            "uitvaartleider_telefoon",
            "ondernemer_registratienummer",
            "achternaam_overledene",
            "locatie",
            "datum_uitvaart",
            "aanvangstijd_uitvaart",
            "aanvangstijd_groeve",
            "soort_begrafenis",
            // "nieuw_of_bestaand_graf",
            // "nieuw_graf_aantal_jaren",
            // "bestaand_graf_verlenging",
            // "bestaand_graf_verlenging_aantal_jaren"
        ];
        // find out which leg we are on
        if (this.getFormData("nieuw_of_bestaand_graf") == 'Nieuw') {
            fields.push("nieuw_graf_aantal_jaren");

        } else if (this.getFormData("nieuw_of_bestaand_graf") == 'Bestaand') {

            if (this.getFormData("bestaand_graf_verlenging") == 'Ja') {
                fields.push("bestaand_graf_verlenging_aantal_jaren");
            }

        }


        let check = true;
        fields.forEach((eachKey: any) => {
            if (!this.validateField(eachKey, this.getFormData(eachKey))) {
                check = false
                // log the exception
                console.log(eachKey)
            }
        })

        return check;
    }
    public pageThreeValid() {
        const fields = [
            "postcode_rechthebbende",
            "straat_en_huisnummer_rechthebbende",
            "geboorteplaats_rechthebbende",
            "geboortedatum_rechthebbende",
            "geslacht_rechthebbende",
            "voornamen_letters_rechthebbende",
            "achternaam_rechthebbende",
            "bsn_nummer_rechthebbende",
            "land_rechthebbende",
            "gemeente_rechthebbende",
            "woonplaats_rechthebbende",
            "telefoonnummer_rechthebbende",
            "email_rechthebbende"
        ];

        let check = true;
        fields.forEach((eachKey: any) => {
            if (!this.validateField(eachKey, this.getFormData(eachKey))) {
                check = false;
                console.log(eachKey)
            }
        });

        return check;
    }
    public pageFourValid() {
        const fields = [
            "bsn_nummer_overledene",
            "voornamen_letters_overledene",
            "geslacht_overledene",
            "geboortedatum_overledene",
            "geboorteplaats_overledene",
            "geboorteland_overledene",
            "naam_partner_overledene",
            "laatste_woonplaats_overledene",
            "datum_overlijden",
            "gemeente_overlijden",
            "land_overlijden"

        ];

        let check = true;
        fields.forEach((eachKey: any) => {
            if (!this.validateField(eachKey, this.getFormData(eachKey))) {
                check = false;
                console.log(eachKey)
            }
        });

        return check;
    }
    public pageFiveValid() {
        return true
    }
    public pageSixValid() {

        const fields = [
            "voor_en_achternaam_ondertekening_uitvaartonderneming",
            "datum_ondertekening_uitvaartonderneming",
            "digitale_handtekening_uitvaartonderneming"
        ];

        let check = true;
        fields.forEach((eachKey: any) => {
            if (!this.validateField(eachKey, this.getFormData(eachKey))) {
                check = false;
                console.log(eachKey)
            }
        });

        return check;
    }
    public pageSevenValid() {
        const fields = [
            "voor_en_achternaam_beheerder",
            "digitale_handtekening_beheerder"
        ];

        let check = true;
        fields.forEach((eachKey: any) => {
            if (!this.validateField(eachKey, this.getFormData(eachKey))) {
                check = false;
                console.log(eachKey)
            }
        });

        return check;
    }
    public calculateBurialCosts(): any {
        this._burialCosts = [];
        // burial costs for the type
        const _allCosts = this.getGeneralCostsPerBurialType();
        // console.log(_allCosts)
        if (!_allCosts) {
            return false
        }
        this._burialCosts.push(..._allCosts as any);
        // holiday surcharge check
        if (this._holidaySurCharge) {
            this._burialCosts.push(this._controlData.COSTS.filter((cost: Cost) => { return cost.burial_type == "Feestdag toeslag" })[0])
        }

        // add the general costs
        this._burialCosts.push(...this.getGeneralCosts(this._funeralType as string));
        let u = this.calculateSubTotal()
        this._burialCosts.push(u)
        if (this._session) {
            this._session.burialCosts = JSON.stringify(this._burialCosts)
        }
        this._burialCostTotal = u.cost
        this._burialCostTotalUnFormatted = u.costRaw;
        return this._burialCosts;
    }

    public calculateFacilitiesCosts(): any {
        const c = calculateTotalCosts(this._formData, this._facilities as any);
        this._facilitiesCostTotal = this.calculateFacilitiesCostsSubtotal(c)
        return [...c, this._facilitiesCostTotal]
    }
    public formatToEuroCurrency(amount: number): string {
        return amount.toLocaleString('nl-NL', { style: 'currency', currency: 'EUR' })
    }

    public calculateFacilitiesCostsSubtotal(c: any): any {
        let _total = 0;
        c.forEach((eachCost: any) => {
            if (typeof eachCost.totalCost == 'number') {
                _total += eachCost.totalCost;
            }
        })
        this._facilitiesTotalcostAmount = _total
        this._facilitiesTotalcostAmountFormatted = this.formatToEuroCurrency(_total)
        this._totalCosts = Number(this._burialCostTotal.replace(',00', '').replace('.', '').replace('€', '').trim()) + Number(this._facilitiesTotalcostAmount)

        if (isNaN(this._totalCosts)) {
            this._totalCosts = 0
        }
        this._totalCostsFormatted = `${this.formatToEuroCurrency(this._totalCosts)}`;

        return {
            facility: '',
            category: 'Subtotaal',
            option: '',
            price: '',
            price_unit: '',
            totalCost: `${_total}`
        }
    }
    public calculateSubTotal() {

        let subTotal = 0.00;
        this._burialCosts.forEach((eachCost: Cost) => {
            if (eachCost) {
                // console.log(eachCost.cost)
                let cst = eachCost.cost?.replaceAll(' ', '').replaceAll('.00', '').trim();
                let _numberCost = parseFloat(cst as string);
                console.log(_numberCost, subTotal)
                subTotal += _numberCost;
            }

        })
        return {
            _id: 'SUBTOTAL',
            cost: `${this.formatToEuroCurrency(subTotal)}`,
            costRaw: subTotal,
            type: 'Subtotaal'
        }

    }

    public filterYearsByBurialTypeAndNewExisting() {

        return this._controlData.COSTS.filter((eachCost: Cost) => {
            return eachCost.burial_type == this._formData.soort_begrafenis && eachCost.new_exist == this._formData.nieuw_of_bestaand_graf && eachCost.type === 'Onderhoud'
        }).map((eachOne: Cost) => {
            return { value: eachOne.years, label: eachOne.years }
        })
    }
    public filterCostsByfuneralType(funeralType: string): Cost[] {
        return this._controlData.COSTS.filter((entry: Cost) => {
            return entry.burial_type == funeralType
        });
    }

    public filterCostsByNewExisting(newExist: string, costs: Cost[]): any[] {
        return costs.filter((entry: Cost) => {
            return entry.new_exist == newExist
        });
    }

    public filterCostsByCostType(type: string, costs: Cost[]): any[] {
        return costs.filter((entry: Cost) => {
            return entry.type == type
        });
    }

    public setFuneralType(type: string) {
        this._funeralType = type;
        const _fType = this._controlData.FUNERALTYPES.find((eachOne: FuneralType) => {
            return eachOne.burial_type == type
        }) as FuneralType;
        if (_fType && this._formData) {
            this._formData.rightsType = _fType.rights_type;
        }

    }

    public setSession(session: SessionObject, newSession?: boolean) {
        // console.log('setting session')
        this._hasPrevSession = true;
        this._session = session;
        let stringSession: any

        if (session.selectedFacilities) {
            try {
                stringSession = JSON.parse(atob(session.selectedFacilities))
            } catch (e) {
                stringSession = JSON.parse(session.selectedFacilities)
            }
        }

        console.log(stringSession);
        this._selectedFacilities = session.selectedFacilities ? stringSession : [];
        if (session.status == 'INPROGRESS_SUBMITTED' || session.status == 'COMPLETED_APPROVED' || session.status == 'SUBMITTED_EMAILED' || session.status == 'COMPLETED_ARCHIVED') {
            this._READ_ONLY = true;
        }

        if (newSession) {
            this._newSession = true
        }
    }

    public hasPrevSession() {
        return this._hasPrevSession
    }

    public getSession() {
        return this._session;
    }

    public getFuneralCosts() {
        return this._costs;
    }

    public addCost(cost: Cost) {
        this._costs.push(cost);
    }
    public setFormData(key: keyof FormData, value: any) {
        if (this._session) {
            console.log(`Set: ${key} to: ${value}`)
            this._formData = { ...this._formData, [key as keyof FormData]: value } as FormData;
            if (key == 'ondernemer_email') {
                this._session.userEmail = value;
            }
            if (key == 'locatie') {
                let adminEmail = this._controlData.LOCATIONS.filter((eachOne: any) => {
                    return eachOne.location == value
                })[0].email_administrator;
                this._session.adminEmail = adminEmail;
            }
            if (key == 'soort_begrafenis') {
                this.setFuneralType(value)
            }
            console.log(this._formData)
        }

    }

    public filteredFuneralTypes(location: string) {
        const locationKey = `location_${location.replace(/[^a-zA-Z]/g, '')}`;
        return this._controlData.FUNERALTYPES.filter((funeralType: FuneralType) => {
            return funeralType[locationKey as keyof FuneralType] !== false;
        });
    }

    public getCostByType(type: string) {
        return this._controlData.COSTS.filter((cost: Cost) => { return cost.burial_type == type })
    }
    public checkKvKNumber(kvk: string) {
        let check = false;
        this._controlData.CHAMBERNUMBERS.forEach((eachKvk: any) => {
            if (eachKvk.validNumbers == kvk) {
                check = true;
            }
        })
        return check;
    }
    public checkForHoliday(funeralDate: any) {

        if (!funeralDate) {
            return false
        }
        if (typeof funeralDate == 'string') {
        funeralDate = parseDateFromEUString(funeralDate)
        } 

        let check = false

        if(funeralDate.getDay() == 0){
           return true
        }

        this._controlData.HOLIDAYS.forEach((eachHoliday: any) => {
            if (isSameDay(funeralDate, eachHoliday.holiday_date)) {
                check = true
                this._holidaySurCharge = true;

            }
        })

        return check;
    }

    public checkForSunday(funeralDate: any) {
        console.log(funeralDate)
        if (!funeralDate) {
            return false
        }
        
        if((parseDateFromEUString(funeralDate) instanceof Date)){
            
            try {
               // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
               const dayOfWeek = funeralDate.getDay();
               console.log(dayOfWeek)
                   // Check if the day of the week is Sunday (0) or Saturday (6)
               return dayOfWeek === 0;
       
            } catch(e){
                return false
            }

        }
        else {
            return false
        }

    }

    public getEncryptedFormData() {
        return btoa(JSON.stringify(this._formData))
    }

    public setInProgressFormData(data: string) {
        // console.log(data)
        try {
            let decrypted = atob(data);

            if (decrypted) {
                this._formData = JSON.parse(decrypted);
                let _holidaySurChargeCheck = this.checkForHoliday(this._formData.datum_uitvaart)
                if (_holidaySurChargeCheck) {
                    this._holidaySurCharge = true;
                }
                // console.log(this._formData)
            }
        } catch (e: any) {
            throw new Error('Cannot decrypt previous form data.')
        }
    }

    public getFacilitiesByLocation() {
        let _final: any = {};
        const _facls = this._controlData.FACILITIES.filter((eachFac: Facility) => {
            return eachFac.location == this._formData.locatie
        });

        let u: any = {};
        _facls.forEach((eachOne: Facility) => {
            if (!u.hasOwnProperty(eachOne.category)) {
                u[eachOne.category] = [eachOne];
            } else {
                u[eachOne.category].push(eachOne);
            }
        });
        Object.keys(u).forEach((eachOne: string) => {
            if (u[eachOne].length > 0) {
                _final[eachOne] = u[eachOne];
            }
        })

        this._facilities = _final;
        return _final;
    }
}


// util functions
function parseDate(dateString: string): Date {
    // Split the input string by '/'
    const parts = dateString.split('/');

    // Extract year, month, and day from the parts array
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript Date
    const day = parseInt(parts[2], 10);

    // Create and return a new Date object
    return new Date(year, month, day);
}

function isSameDay(date1: Date, date2: Date): boolean {


    return date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate();
}


type MainObject = {
    [key: string]: any;
};

type CostItem = {
    _id: string;
    location: string;
    seq: number;
    category: string;
    facility: string;
    control: string;
    option: string;
    price: string;
    price_unit: string;
    max?: number;
    facilities_description?: string;
    surcharge?: string;
    extra?: string;
};

type CostCategory = {
    [category: string]: CostItem[];
};

type ResultItem = {
    facility: string;
    category: string;
    option: string;
    price: string;
    price_unit: string;
    totalCost: number;
};

function calculateTotalCosts(mainObject: MainObject, costsArray: CostCategory): ResultItem[] {
    
    const filteredMainObject: MainObject = Object.keys(mainObject).reduce((acc, key) => {
        if (!(key in FormData)) {
            // console.log(key)
            acc[key] = mainObject[key];
        }
        return acc;
    }, {} as MainObject);

    const selectedOptions = Object.keys(filteredMainObject) // .filter(key => filteredMainObject[key] === true || (typeof filteredMainObject[key] === "string" && filteredMainObject[key].toLowerCase() === "ja"));
    const result: ResultItem[] = [];
    const msPerDay = 24 * 60 * 60 * 1000;
    // console.log(JSON.stringify(selectedOptions))
    Object.values(costsArray).forEach(category => {

        category.forEach(costItem => {
            if (selectedOptions.includes(costItem.facility) && filteredMainObject[costItem.facility] !== false) {

                // determine what type of cost it is by looking for dates
                const toKey = `${costItem.facility}_TO`;
                const fromKey = `${costItem.facility}_FROM`;

                // if to and froom are not in the main object, skip this cost item
                if(filteredMainObject[toKey] === '' || filteredMainObject[fromKey] === ''){
                    return
                }

                
                let price = costItem.price ? parseFloat(costItem.price) : 0;
                let price_unit = costItem.price_unit || '';
                let totalCost = price;

                if (price_unit === 'per dag') {
                    const fromDate = new Date(mainObject[fromKey]);
                    const toDate = new Date(mainObject[toKey]);
                    const numberOfDays = Math.ceil((toDate.getTime() - fromDate.getTime()) / msPerDay);
                    totalCost = totalCost * numberOfDays;
                } else {

                }
                if(!isNaN(totalCost)){
                    result.push({
                        facility: costItem.facility,
                        category: costItem.category,
                        option: filteredMainObject[costItem.facility],
                        price: costItem.price || '',
                        price_unit: price_unit,
                        totalCost: totalCost
                    });

                }
                
            }
        });
    });

    return result;
}